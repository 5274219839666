/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

:root {
	/* Set the background of the entire app */
	--ion-background-color: white;
	--ion-font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
		'Roboto', sans-serif;

	/** primary **/

	--ion-color-primary: #05a7e5;
	--ion-color-primary-rgb: 5, 167, 229;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0, 0, 0;
	--ion-color-primary-shade: #0493ca;
	--ion-color-primary-tint: #1eb0e8;

	/** secondary **/
	--ion-color-secondary: #9ace00;
	--ion-color-secondary-rgb: 154, 206, 0;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary-shade: #88b500;
	--ion-color-secondary-tint: #a4d31a;

	/** tertiary **/
	--ion-color-tertiary: #858585;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #e94332;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #444444;
	--ion-color-dark-rgb: 68, 68, 68;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #3c3c3c;
	--ion-color-dark-tint: #575757;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #f5f5f5;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
}
ion-button {
	text-transform: none;
}

.custom-alert .alert-button-group {
	padding: 8px;
}

button.alert-button.alert-button-confirm {
	background-color: var(--ion-color-primary);
	color: var(--ion-color-tertiary-contrast);
	/* color: var(--ion-color-primary-contrast); */
	border-radius: 20px;
	padding: 0 30px;
}
button.alert-button.alert-button-cancel {
	background-color: var(--ion-color-danger);
	color: var(--ion-color-primary-contrast);
}

.dashborad-grid-container .md ion-card-subtitle {
	font-size: 11px;
	color: #1a1a1a;
	font-weight: 400;
}

.dashborad-grid-container .md ion-card {
	border: 1px solid #3880ff;
	border-radius: 2px;
}
.dashborad-grid-container.grid-last .md ion-col:last-child ion-card {
	border: 1px solid red;
}

.dashboard-website .md .websitecopy {
	background: #7b7b7b;
	text-align: center;
}

.dashboard-website .md .websitelabel {
	line-height: 30px;
}

.dashboard-website .md .websitelink {
	line-height: 30px;
}

.welcomestorename {
	font-size: 18px;
	color: #1a1a1a;
}
.dashboard-fnt-sz {
	font-size: 15px;
	/* color: #1a1a1a;  //added */
}
.dashboard-cta {
	font-size: 12px;
	width: 100%;
	text-transform: none;
	border-radius: 100px;
}

.txt {
	text-transform: none;
}

.fnt-sz28 {
	font-size: 28px;
}
.horizontal-scroll {
	overflow: auto;
	white-space: nowrap;
}

.iklogo {
	width: 120px;
	margin: 0 auto;
	margin-top: 2%;
}

.iklogologin {
	padding: 15px;
	border: 1px solid #eeeeee;
	width: 150px;
	margin: 0 auto;
	border-radius: 50px;
	margin-top: 10%;
	margin-bottom: 15%;
}

.aiimage {
	width: 50px;
}

.iklogologindt {
	padding: 15px;
	width: 350px;
	margin: 0 auto;
	margin-bottom: 3%;
}
.storeTypeName {
	font-size: 16px;
}

.loginButtonMobile {
	display: block;
	width: 90%;
	margin: 0px auto;
}
.loginButtonDt {
	display: block;
	width: 20%;
	margin: 0px auto;
	margin-top: 20px;
}
.dtLogin {
	margin: 0px;
}

.center-grid {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) !important;
	display: block !important;
	width: 40%;
	background-color: var(--ion-color-medium-contrast);
	border: 1px solid #eeeeee;
	border-radius: 8px;
	box-shadow: 0 2px 8px 0 rgb(26 24 30 / 4%);
}

.responsive-width {
	width: 80%;
	margin: 0 auto;
}

.outerContainerdt {
	background-image: url(../images/purple-bg.png) !important;
	background-position: top center;
	background-repeat: no-repeat;
	--box-shadow: none !important;
	--border: 0px !important;
	height: 100%;
	z-index: 0;
}

.outerContainerdt::after {
	background-image: none !important;
}

.displayLaptopdt {
	z-index: 1;
	max-width: 800px;
	align-items: center;
	width: auto;
	margin: auto;
}

.steps-img {
	width: 500px;
	margin-left: 100px;
}

.steps-text {
	margin-top: 80px;
}

.table-holder {
	padding: 15px 15px 0 15px;
}
.table-holder table {
	width: 100%;
	font-size: 15px;
	font-weight: 400;
}
.table-holder table td:last-child {
	text-align: right;
}
.table-holder .totals {
	font-weight: bold;
}
span.tag-wrapper.ReactTags__tag button.ReactTags__remove {
	background: red;
	color: white;
	margin-left: 5px;
	font-size: 15px;
	border-radius: 100px;
}
span.tag-wrapper.ReactTags__tag {
	display: inline-block;
	padding: 5px 5px 5px 10px;
	background: #f7f7f7;
	border: 1px solid #cccccc;
	margin-right: 10px;
	border-radius: 2px;
}

#sharemodal .modal-wrapper.sc-ion-modal-md {
	width: 400px;
	max-width: 100%;
	margin: 0px 15px;
	border-radius: 12px;
	height: 40vh;
}

#statusmodal .modal-wrapper.sc-ion-modal-md {
	width: 400px;
	max-width: 100%;
	margin: 0px 15px;
	border-radius: 12px;
	box-shadow: 0px 0px 20px #8b8b8b;
	height: 80vh;
}
.fullscreen {
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
	margin: 0;
	padding: 0;
}

/* changes -> dark */
/* @media (prefers-color-scheme: dark) {

   * Dark Colors
   * -------------------------------------------
  

	body {
		--ion-color-primary: #428cff;
		--ion-color-primary-rgb: 66, 140, 255;
		--ion-color-primary-contrast: #ffffff;
		--ion-color-primary-contrast-rgb: 255, 255, 255;
		--ion-color-primary-shade: #3a7be0;
		--ion-color-primary-tint: #5598ff;

		--ion-color-secondary: #50c8ff;
		--ion-color-secondary-rgb: 80, 200, 255;
		--ion-color-secondary-contrast: #ffffff;
		--ion-color-secondary-contrast-rgb: 255, 255, 255;
		--ion-color-secondary-shade: #46b0e0;
		--ion-color-secondary-tint: #62ceff;

		--ion-color-tertiary: #6a64ff;
		--ion-color-tertiary-rgb: 106, 100, 255;
		--ion-color-tertiary-contrast: #ffffff;
		--ion-color-tertiary-contrast-rgb: 255, 255, 255;
		--ion-color-tertiary-shade: #5d58e0;
		--ion-color-tertiary-tint: #7974ff;

		--ion-color-success: #2fdf75;
		--ion-color-success-rgb: 47, 223, 117;
		--ion-color-success-contrast: #000000;
		--ion-color-success-contrast-rgb: 0, 0, 0;
		--ion-color-success-shade: #29c467;
		--ion-color-success-tint: #44e283;

		--ion-color-warning: #ffd534;
		--ion-color-warning-rgb: 255, 213, 52;
		--ion-color-warning-contrast: #000000;
		--ion-color-warning-contrast-rgb: 0, 0, 0;
		--ion-color-warning-shade: #e0bb2e;
		--ion-color-warning-tint: #ffd948;

		--ion-color-danger: #ff4961;
		--ion-color-danger-rgb: 255, 73, 97;
		--ion-color-danger-contrast: #ffffff;
		--ion-color-danger-contrast-rgb: 255, 255, 255;
		--ion-color-danger-shade: #e04055;
		--ion-color-danger-tint: #ff5b71;

		--ion-color-dark: #f4f5f8;
		--ion-color-dark-rgb: 244, 245, 248;
		--ion-color-dark-contrast: #000000;
		--ion-color-dark-contrast-rgb: 0, 0, 0;
		--ion-color-dark-shade: #d7d8da;
		--ion-color-dark-tint: #f5f6f9;

		--ion-color-medium: #989aa2;
		--ion-color-medium-rgb: 152, 154, 162;
		--ion-color-medium-contrast: #000000;
		--ion-color-medium-contrast-rgb: 0, 0, 0;
		--ion-color-medium-shade: #86888f;
		--ion-color-medium-tint: #a2a4ab;

		--ion-color-light: #222428;
		--ion-color-light-rgb: 34, 36, 40;
		--ion-color-light-contrast: #ffffff;
		--ion-color-light-contrast-rgb: 255, 255, 255;
		--ion-color-light-shade: #1e2023;
		--ion-color-light-tint: #383a3e;
	}

	
   * iOS Dark Theme
   * -------------------------------------------
  

	.ios body {
		--ion-background-color: #000000;
		--ion-background-color-rgb: 0, 0, 0;

		--ion-text-color: #ffffff;
		--ion-text-color-rgb: 255, 255, 255;

		--ion-color-step-50: #0d0d0d;
		--ion-color-step-100: #1a1a1a;
		--ion-color-step-150: #262626;
		--ion-color-step-200: #333333;
		--ion-color-step-250: #404040;
		--ion-color-step-300: #4d4d4d;
		--ion-color-step-350: #595959;
		--ion-color-step-400: #666666;
		--ion-color-step-450: #737373;
		--ion-color-step-500: #808080;
		--ion-color-step-550: #8c8c8c;
		--ion-color-step-600: #999999;
		--ion-color-step-650: #a6a6a6;
		--ion-color-step-700: #b3b3b3;
		--ion-color-step-750: #bfbfbf;
		--ion-color-step-800: #cccccc;
		--ion-color-step-850: #d9d9d9;
		--ion-color-step-900: #e6e6e6;
		--ion-color-step-950: #f2f2f2;

		--ion-item-background: #000000;

		--ion-card-background: #1c1c1d;
	}

	.ios ion-modal {
		--ion-background-color: var(--ion-color-step-100);
		--ion-toolbar-background: var(--ion-color-step-150);
		--ion-toolbar-border-color: var(--ion-color-step-250);
	}

	
   * Material Design Dark Theme
   * -------------------------------------------


	.md body {
		--ion-background-color: #121212;
		--ion-background-color-rgb: 18, 18, 18;

		--ion-text-color: #ffffff;
		--ion-text-color-rgb: 255, 255, 255;

		--ion-border-color: #222222;

		--ion-color-step-50: #1e1e1e;
		--ion-color-step-100: #2a2a2a;
		--ion-color-step-150: #363636;
		--ion-color-step-200: #414141;
		--ion-color-step-250: #4d4d4d;
		--ion-color-step-300: #595959;
		--ion-color-step-350: #656565;
		--ion-color-step-400: #717171;
		--ion-color-step-450: #7d7d7d;
		--ion-color-step-500: #898989;
		--ion-color-step-550: #949494;
		--ion-color-step-600: #a0a0a0;
		--ion-color-step-650: #acacac;
		--ion-color-step-700: #b8b8b8;
		--ion-color-step-750: #c4c4c4;
		--ion-color-step-800: #d0d0d0;
		--ion-color-step-850: #dbdbdb;
		--ion-color-step-900: #e7e7e7;
		--ion-color-step-950: #f3f3f3;

		--ion-item-background: #1e1e1e;

		--ion-toolbar-background: #1f1f1f;

		--ion-tab-bar-background: #1f1f1f;

		--ion-card-background: #1e1e1e;
	}
}    */

.ReactCrop__image {
	touch-action: auto !important;
}

input:focus {
	outline: none;
}

@media screen and (max-width: 767px) {
	.top-margin-on-mobile {
		margin-top: 10px;
	}
}

.ql-editor {
	min-height: 250px !important;
}

.ioncol-style {
	border-right: 1px solid rgba(100, 100, 100, 0.1);
}

.ionrow-style {
	border-bottom: 1px solid rgba(100, 100, 100, 0.1);
}
/* added */
ion-modal.fullscreen {
	--width: 100%;
	--height: 100%;
	--border-radius: 0;
}
/* end added */

@media screen and (max-width: 767px) {
	.center-grid {
		width: 80%;
	}
	.responsive-width {
		width: 100%;
	}
}
@media screen and (max-width: 425px) {
	.center-grid {
		width: 100%;
	}
}
